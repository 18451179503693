<script setup lang="ts">
import {defineProps, ref, watch} from 'vue';

const props = defineProps({
    errors: {
        type: [String, Boolean, Array, Object],
        required: false,
        default: false,
    },
    result: {
        type: [String, Boolean, Array, Object],
        required: false,
        default: false,
    },
});

// Helper functions
const isString = (value: any): boolean => typeof value === 'string';
const isObject = (value: any): boolean => typeof value === 'object' && !Array.isArray(value);
const isArray = (value: any): boolean => Array.isArray(value);

const localErrors = ref(props.errors);
const localResult = ref(props.result);

watch(
    () => props.errors,
    (newValue) => {
        if (newValue) {
            localResult.value = false; // Reset result if errors change
        }
        localErrors.value = newValue;
    }
);

watch(
    () => props.result,
    (newValue) => {
        if (newValue) {
            localErrors.value = false; // Reset errors if result changes
        }
        localResult.value = newValue;
    }
);
</script>

<template>
    <div v-if="localErrors || localResult" class="mt-4">
        <!-- Errors Section -->
        <template v-if="localErrors">
            <div v-if="isString(localErrors)" class="alert alert-danger">{{ localErrors }}</div>
            <div v-else-if="isObject(localErrors)">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item list-group-item-danger" v-for="(item, key) in localErrors" :key="key">
                        {{ key }} - {{ item.toString() }}
                    </li>
                </ul>
            </div>
            <div v-else-if="isArray(localErrors)">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item list-group-item-danger" v-for="(item, index) in localErrors" :key="index">
                        {{ item.toString() }}
                    </li>
                </ul>
            </div>
        </template>

        <!-- Result Section -->
        <template v-if="localResult">
            <div class="alert alert-success" v-if="isString(localResult)">
                {{ localResult }}
            </div>
        </template>
    </div>
</template>
